import React from "react";
import logoNIW from "../images/niw.png"
import logoFIO from "../images/fio.png"
import logoFLOW from "../images/flow.png"

function Footer() {

  return (
    <footer className="bg-white shadow-inner shadow">
      <div className="flex flex-row items-center justify-between p-2 max-w-4xl mx-auto md:p-4">
        <a href="https://fundacjaflow.edu.pl/" className="flex-none mr-20" target="_blank" rel="noopener noreferrer">
          <img src={logoFLOW} alt="FLOW" className="h-16"/>
        </a>
        <a href="https://www.niw.gov.pl" className="flex-none mr-3" target="_blank" rel="noopener noreferrer">
          <img src={logoNIW} alt="NIW" className="h-16"/>
        </a>
        <span className="flex-grow text-center text-xs">Sfinansowano przez Narodowy Instytut Wolności - Centrum Rozwoju Społeczeństwa Obywatelskiego ze środków Programu Fundusz Inicjatyw Obywatelskich NOWE&nbsp;FIO na&nbsp;lata&nbsp;2021-2030</span>
        <a href="https://www.niw.gov.pl" className="flex-none ml-3" target="_blank" rel="noopener noreferrer">
          <img src={logoFIO} alt="FIO" className="h-16"/>
        </a>
      </div>
    </footer>
  );
}

export default Footer;