import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import edumapaLogo from "../images/logobezherbu.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-white shadow">
      <div className="flex flex-wrap items-center justify-between p-2 max-w-4xl mx-auto md:p-4">
      {/* <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8"> */}

        <Link to="/">
          <img src={edumapaLogo} className="h-12" alt="edumapa"/>
        </Link>

        <button
          className="items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current text-gray-800"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: '/o-edumapie',
              title: 'o edumapie',
            },
            {
              route: '/jak-korzystac',
              title: 'jak korzystać?',
            },
            {
              route: '/scenariusze',
              title: 'scenariusze',
            },
            {
              route: '/konkurs',
              title: 'konkurs',
            },
            {
              route: '/kontakt',
              title: 'kontakt',
            },
          ].map((link) => (
            <Link
              className="block uppercase mt-4 text-lg text-gray font-bold md:inline-block md:mt-0 md:ml-6 hover:-translate-y-1"
              activeClassName="text-red-800"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;