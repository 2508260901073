import PropTypes from "prop-types";
import React from "react";

import Header from "../components/header";
import Footer from "../components/footer";

function DefaultLayout({ children, marginless, footerLess }) {
    // const mainClassName = marginless ? "flex-1 w-full" : "" ;
    const mainClassName = marginless ? "flex-1 w-full h-full" : "flex-1 w-full max-w-4xl px-4 py-4 mx-auto md:px-8 md:py-8" ;
  return (
    <div className="flex flex-col h-screen font-sans text-gray-900">
      <Header />

      <main className={mainClassName}>
        {children}
      </main>

      { !footerLess &&  <Footer /> }

    </div>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

DefaultLayout.defaultProps = {
    maringless: false,
};

export default DefaultLayout;